class LoadService {
  constructor() {}
  resources = () => ({
    load: {
      uri: `/v1/load`,
      method: ['get']
    }
  });
}

export default LoadService;
