import Vue from 'vue';
import VueRouter from 'vue-router';
import goTo from 'vuetify/es5/services/goto';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/auth/login',
    meta: {
      code: '*'
    }
  },
  {
    path: '/auth',
    component: () => import(/* webpackChunkName: "group-auth" */ '@/layouts/blank-layout/Blanklayout'),
    meta: {
      code: '*'
    },
    children: [
      {
        name: 'authlogin',
        path: 'login',
        component: () => import(/* webpackChunkName: "group-auth-login" */ '@/gquest/authentication/views/Login'),
        meta: {
          code: '*'
        }
      }
    ]
  },
  /************************ VOTACIONES ******************************/
  {
    path: '/inicio',
    component: () => import(/* webpackChunkName: "group-inicio" */ '@/layouts/full-layout/Layout'),
    meta: {
      code: '*'
    },
    children: [
      {
        name: 'inicio',
        path: '',
        component: () => import(/* webpackChunkName: "group-inicio-inicio" */ '@/gquest/inicio/views/Inicio'),
        meta: {
          code: '*'
        }
      }
    ]
  },
  {
    path: '/administracion',
    component: () => import(/* webpackChunkName: "group-votacion" */ '@/layouts/full-layout/Layout'),
    meta: {
      code: '*'
    },
    children: [
      {
        name: 'matriz_usuarios',
        path: 'matriz_usuarios',
        component: () => import('@/gquest/administracion/usuario/views/MatrizUsuario'),
        meta: {
          code: '*'
        }
      },
      {
        name: 'matriz_campana',
        path: 'matriz_campana',
        component: () => import('@/gquest/administracion/campana/views/MatrizCampana'),
        meta: {
          code: '*'
        }
      },
      {
        name: 'formato_encuesta',
        path: 'formato_encuesta',
        component: () => import('@/gquest/encuesta/formato_encuesta/views/FormatoEncuesta'),
        meta: {
          code: '*'
        }
      },
      {
        name: 'formato_encuesta_id',
        path: 'formato_encuesta/:id',
        component: () => import('@/gquest/encuesta/formato_encuesta/views/FormatoEncuesta'),
        meta: {
          code: '*'
        }
      },
      {
        name: 'matriz_encuestas_asignadas',
        path: 'matriz_encuestas_asignadas',
        component: () => import('@/gquest/encuesta/responder_encuesta/views/MatrizEncuestasAsignadas'),
        meta: {
          code: '*'
        }
      },
      {
        name: 'responder_encuesta_id',
        path: 'responder_encuesta/:id',
        component: () => import('@/gquest/encuesta/responder_encuesta/views/ResponderEncuesta'),
        meta: {
          code: '*'
        }
      }
    ]
  },
  {
    path: '/encuesta',
    component: () => import(/* webpackChunkName: "group-votacion" */ '@/layouts/full-layout/Layout'),
    meta: {
      code: '*'
    },
    children: [
      {
        name: 'matriz_formato_encuesta',
        path: 'matriz_formato_encuesta',
        component: () => import('@/gquest/encuesta/formato_encuesta/views/MatrizFormatoEncuesta'),
        meta: {
          code: '*'
        }
      },
      {
        name: 'formato_encuesta',
        path: 'formato_encuesta',
        component: () => import('@/gquest/encuesta/formato_encuesta/views/FormatoEncuesta'),
        meta: {
          code: '*'
        }
      },
      {
        name: 'formato_encuesta_id',
        path: 'formato_encuesta/:id',
        component: () => import('@/gquest/encuesta/formato_encuesta/views/FormatoEncuesta'),
        meta: {
          code: '*'
        }
      },
      {
        name: 'matriz_encuestas_asignadas',
        path: 'matriz_encuestas_asignadas',
        component: () => import('@/gquest/encuesta/responder_encuesta/views/MatrizEncuestasAsignadas'),
        meta: {
          code: '*'
        }
      },
      {
        name: 'responder_encuesta_id',
        path: 'responder_encuesta/:id',
        component: () => import('@/gquest/encuesta/responder_encuesta/views/ResponderEncuesta'),
        meta: {
          code: '*'
        }
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import(/* webpackChunkName: "unauthorized" */ '@/components4x/authentication/Unauthorized'),
    meta: {
      code: '*'
    }
  },
  {
    path: '*',
    component: () => import('@/views/authentication/Error'),
    meta: {
      code: '*'
    }
  },
  {
    path: '/responder_encuesta_url/:id',
    component: () => import('@/gquest/encuesta/responder_encuesta/views/ResponderEncuestaUrl'),
    meta: {
      code: '*'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  }
  // This is for the scroll top when click on any router link
});

export default router;
