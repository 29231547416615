import Vue from 'vue';
import App from './App.vue';
import LoadSetting from '@/components4x/authentication/LoadSetting';
// eslint-disable-next-line no-unused-vars
import { loadPermissions } from '@/components4x/authentication/LoadSettings';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import Vuebar from 'vuebar';
import './plugins/base';
import VueSkycons from 'vue-skycons';
import VuePapaParse from 'vue-papa-parse';
import shortkey from 'vue-shortkey';
import VueGapi from 'vue-gapi';

//PLUGIN ENIGMA4X
import httpService4X from './services/httpInstallService';

Vue.use(httpService4X, { token: true, name: 'led17h9p2x', store, login: '/auth/login', unauthorized: '/unauthorized' });

Vue.use(VueGapi, {
  apiKey: process.env.VUE_APP_API_KEY,
  clientId: process.env.VUE_APP_CLIENT_ID,
  discoveryDocs: [process.env.VUE_APP_DISCOVERY_DOCS],
  scope: process.env.VUE_APP_SCOPE
});

Vue.use(VuePapaParse);
Vue.use(shortkey);

Vue.use(VueSkycons, {
  color: '#1e88e5'
});

Vue.use(Vuebar);

Vue.config.productionTip = false;

// new Vue({
//   vuetify,
//   store,
//   router,
//   render: (h) => h(App)
// }).$mount('#app');

let instanceSettings = null;
// eslint-disable-next-line no-unused-vars
let intanceApp = null;

async function initializeApp() {
  instanceSettings = new Vue({
    render: (h) => h(LoadSetting)
  }).$mount('#loadsettings');

  // await loadSettings(store, vuetify);
  await loadPermissions(store);
}

initializeApp().then(() => {
  instanceSettings.$destroy();
  instanceSettings.$el.parentNode.removeChild(instanceSettings.$el);
  intanceApp = new Vue({
    vuetify,
    store,
    router,
    render: (h) => h(App)
  }).$mount('#app');
});
