<template>
  <v-list-group
    v-if="verifyAuthAcl(item.meta.code)"
    :group="group"
    :prepend-icon="item.icon"
    :sub-group="subGroup"
    :active-class="`primary white--text`"
  >
    <template v-slot:activator>
      <v-list-item-icon v-if="item.sicon" class="sicon">
        <v-icon v-text="item.sicon" />
      </v-list-item-icon>
      <v-list-item-icon v-if="item.avatar">
        <img :src="item.avatar" width="30" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="item.title" />
      </v-list-item-content>
    </template>

    <div v-for="(child, i) in children" :key="`child-sub-group-${i}`">
      <div v-if="child.children" :key="`div-sub-group-${i}`">
        <BaseItemSubGroup
          v-if="verifyAuthAcl(child.meta.code)"
          :key="`sub-group-${i}`"
          :item="child"
          class="second-dd"
        />
      </div>
      <div v-else :key="`div-sub-item-${i}`">
        <BaseItem v-if="verifyAuthAcl(child.meta.code)" :key="`sub-item-${i}`" :item="child" text />
      </div>
    </div>
  </v-list-group>
</template>

<script>
// Utilities
import kebabCase from 'lodash/kebabCase';

export default {
  name: 'BaseItemGroup',

  inheritAttrs: false,

  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        group: undefined,
        title: undefined,
        meta: {
          code: ''
        },
        children: []
      })
    },
    subGroup: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ListPermissions() {
      return [...this.$store.getters.getFullPermissions];
    },
    children() {
      return this.item.children.map((item) => ({
        ...item,
        to: !item.to ? undefined : `${this.item.group}/${item.to}`
      }));
    },
    group() {
      return this.genGroup(this.item.children);
    }
  },

  methods: {
    verifyAuthAcl(code) {
      if (code === '*') {
        return true;
      }
      const index = this.ListPermissions.indexOf(code);
      return index !== -1;
    },
    genGroup(children) {
      return children
        .filter((item) => item.to)
        .map((item) => {
          const parent = item.group || this.item.group;
          let group = `${parent}/${kebabCase(item.to)}`;

          if (item.children) {
            group = `${group}|${this.genGroup(item.children)}`;
          }

          return group;
        })
        .join('|');
    }
  }
};
</script>

<style></style>
