<template>
  <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
    <router-view />
  </v-app>
</template>

<script>
//import AppService from './AppService';
/*import Swal from 'sweetalert2';*/

export default {
  name: 'App',
  data: () => ({}),
  components: {},
  methods: {},
  async mounted() {
    // eslint-disable-next-line no-unused-vars
    // await new Promise((r) => setTimeout(r, 5000));
  },
  async created() {}
};
</script>
