<template>
  <div class=" cls-index">
    <v-row class="page-breadcrumb breadcrumb-static" :style="width == '' ? `width: ${widthBreadcrumb}px;` : width">
      <v-col cols="12" sm="12" class="pa-0 pb-2 ">
        <v-card class="custom-shaodow pa-3 py-2">
          <v-toolbar dense flat class="transparent" height="40px">
            <v-toolbar-title class="pt-3" style="width:100%">
              <div class="d-flex align-center justify-space-between">
                <div>
                  <slot name="options-left"></slot>
                  <span class="text--primary">{{ title }}</span>
                </div>
                <slot name="options-center"></slot>
                <slot name="options-right"></slot>
              </div>
            </v-toolbar-title>
            <!-- <v-toolbar-title class="toobar-extension transparent pb-3" slot="extension">
              <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                <template v-slot:divider v-if="breadcrumbs">
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>
              <slot></slot>
            </v-toolbar-title> -->
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Proxyable from 'vuetify/lib/mixins/proxyable';
export default {
  name: 'BaseBreadcrumb',
  mixins: [Proxyable],
  props: {
    title: String,
    breadcrumbs: Array,
    icon: String,
    width: {
      type: String,
      default: ''
    }
  },
  computed: {
    widthBreadcrumb() {
      return this.$store.state.Width_Breadcrumb;
    }
  },
  watch: {},
  data: () => ({
    sidebarDrawer: false,
    example: 10
  })
};
</script>
<style lang="scss">
.page-breadcrumb {
  margin: -20px -25px 27px -25px;
}
.breadcrumb-static {
  position: fixed;
  z-index: 2;
  width: calc(100% - 243px);
}
</style>
