import Vue from 'vue';
import Vuex from 'vuex';
import { v4 as uuidv4 } from 'uuid';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    Expand_nav: true,
    Width_Breadcrumb: '1290',
    SidebarColor: 'white',
    SidebarBg: '',
    ThemeColor: '#1e88e5',
    surveyFormat: {},
    selection: {
      id: null,
      type: 'S', // S: Seccion, P: Pregunta
      idParent: null,
      position: 1,
      isNew: true
    }
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    CHANGE_EXPAND_NAV(state) {
      state.Expand_nav = !state.Expand_nav;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_THEME_COLOR(state, payload) {
      state.ThemeColor = payload;
    },
    SET_WIDTH_BREADCRUMB(state, payload) {
      state.Width_Breadcrumb = payload + 24;
    },
    CREATE_FORMATO_ENCUESTA(state) {
      console.log('crea nueva encuesta');
      let idFormat = uuidv4();
      let idSection = uuidv4();
      state.surveyFormat = {
        id: idFormat,
        title: '',
        description: '',
        campaign: '',
        headOfficeId: null,
        businessUnitId: null,
        showFirstSection: false,
        startDate: null,
        endDate: null,
        isGlobal: false,
        isAnonymous: false,
        sections: [
          {
            id: idSection,
            title: '',
            description: '',
            position: 1,
            idParent: idFormat,
            status: true,
            isNew: true,
            questions: []
          }
        ]
      };
      state.selection = {
        id: idSection,
        type: 'S', // S: Seccion, P: Pregunta
        idParent: state.surveyFormat.idFormat,
        position: 1,
        isNew: true
      };
      /*state.selection.id = idSection;
      state.selection.idParent = state.surveyFormat.idFormat;*/
    },
    SET_FORMATO_ENCUESTA(state, payload) {
      //console.log('survetFormat: ', state.surveyFormat);
      //console.log('selection: ', state.selection);
      state.surveyFormat = payload;
    },
    SET_FORMATO_SELECCION(state, payload) {
      state.selection = payload;
    },
    RESET_SELECTION_ELEMENT_FORMATO(state) {
      let firstActive = state.surveyFormat.sections.find((el) => el.status === true);
      if (firstActive) {
        let selection = {
          id: firstActive.id,
          type: 'S',
          idParent: firstActive.idParent,
          position: firstActive.position
        };
        state.selection = selection;
      }
    },
    SET_MOSTRAR_PRIMERA_SECCION(state, payload) {
      state.surveyFormat.showFirstSection = payload;
      state.surveyFormat.sections[0].title = 'Sección sin Título';
      let section = state.surveyFormat.sections[0];
      state.selection = { id: section.id, type: 'S', position: section.position, idParent: section.idParent };
    },
    SET_OPTIONS_ESCALA(state, payload) {
      //payload.type --> 1: cambio el de o hasta, 2: cambio el tipo
      let question = state.surveyFormat.sections[payload.posSection].questions[payload.posQuestion];
      let options = question.options;
      console.log(options);
      options = options.filter((option) => option.isNew === false);
      options = options.map((option) => {
        option.status = false;
        return option;
      });

      for (let i = payload.de; i <= payload.hasta; i++) {
        options.push({
          id: uuidv4(),
          idParent: question.id,
          title: '',
          position: i,
          status: true,
          isNew: true,
          hasComment: false
        });
      }

      state.surveyFormat.sections[payload.posSection].questions[payload.posQuestion].options = options;
    },
    ADD_OPTION_PREGUNTA(state, payload) {
      let question = state.surveyFormat.sections[payload.posSection].questions[payload.posQuestion];
      let optionsAuxName = question.options.filter((el) => el.status && !el.hasComment);
      let titleOption = payload.hasComment ? 'Otra...' : 'Opción ' + (optionsAuxName.length + 1);
      state.surveyFormat.sections[payload.posSection].questions[payload.posQuestion].options.push({
        id: uuidv4(),
        idParent: question.id,
        title: titleOption,
        position: question.options.length + 1,
        status: true,
        isNew: true,
        hasComment: payload.hasComment
      });
      let options = state.surveyFormat.sections[payload.posSection].questions[payload.posQuestion].options;
      let posOptionHasComment = options.findIndex((el) => el.hasComment == true);
      if (posOptionHasComment > -1) {
        let optionHasComment = options[posOptionHasComment];
        options.splice(posOptionHasComment, 1);
        options.push(optionHasComment);
        options = options.map((el, key) => {
          el.position = key + 1;
          return el;
        });
        state.surveyFormat.sections[payload.posSection].questions[payload.posQuestion].options = options;
      }
    },
    RESET_OPTIONS_PREGUNTA(state, payload) {
      /*let question = state.surveyFormat.sections[payload.posSection].questions[payload.posQuestion];
      let options = question.options.filter((option) => option.isNew === false);
      state.surveyFormat.sections[payload.posSection].questions[payload.posQuestion].options = options.map((el) => {
        return { ...el, status: false };
      });*/
      state.surveyFormat.sections[payload.posSection].questions[payload.posQuestion].options = [];
      state.surveyFormat.sections[payload.posSection].questions[payload.posQuestion].options.push({
        id: uuidv4(),
        idParent: state.surveyFormat.sections[payload.posSection].questions[payload.posQuestion].id,
        title: 'Opción 1',
        position: 1,
        isNew: true,
        status: true,
        hasComment: false
      });
    }
  },
  actions: {
    addElementoFormato({ commit }, payload) {
      let id = uuidv4();
      let newElement = {};
      let selection = { ...payload.selection };
      let posNewQuestion;

      let idSection = selection.type === 'S' ? selection.id : selection.idParent;
      let section = payload.surveyFormat.sections.find((el) => el.id === idSection);
      switch (payload.type) {
        case 'S':
          newElement = {
            id: id,
            title: 'Sección sin Título ' + (section.position + 1),
            description: '',
            position: section.position + 1,
            idParent: section.idParent,
            status: true,
            isNew: true,
            questions: []
          };
          payload.surveyFormat.sections.splice(selection.position, 0, newElement);
          payload.surveyFormat.sections = payload.surveyFormat.sections.map((el, i) => {
            el.position = i + 1;
            return el;
          });
          break;
        case 'Q':
          posNewQuestion = selection.type === 'Q' ? selection.position + 1 : section.questions.length;
          newElement = {
            id: id,
            title: 'Pregunta ' + (section.questions.length + 1),
            description: '',
            position: payload.surveyFormat.sections[section.position - 1].questions.length + 1,
            type: payload.typeQuestion,
            idParent: section.id,
            required: false,
            status: true,
            isNew: true,
            options: [
              {
                id: uuidv4(),
                idParent: id,
                title: 'Opción 1',
                position: 1,
                isNew: true,
                status: true,
                hasComment: false
              }
            ]
          };
          payload.surveyFormat.sections[section.position - 1].questions.splice(posNewQuestion, 0, newElement);
          payload.surveyFormat.sections[section.position - 1].questions = payload.surveyFormat.sections[
            section.position - 1
          ].questions.map((el, i) => {
            el.position = i + 1;
            return el;
          });
          break;
      }

      commit('SET_FORMATO_ENCUESTA', payload.surveyFormat);
      commit('SET_FORMATO_SELECCION', {
        type: payload.type,
        id: newElement.id,
        position: newElement.position,
        idParent: newElement.idParent
      });
    },
    deleteElementoFormato({ commit }, payload) {
      let newSurveyFormat = payload.surveyFormat;
      let sectionsActives = newSurveyFormat.sections.filter((section) => section.status === true);
      if (sectionsActives.length === 1 && payload.type === 'S') {
        newSurveyFormat.showFirstSection = false;
        newSurveyFormat.sections[0].title = '';
      }

      newSurveyFormat.sections = newSurveyFormat.sections.map((section) => {
        if (payload.type === 'S' && section.id === payload.id && sectionsActives.length > 1) section.status = false;
        section.questions = section.questions.map((question) => {
          if (
            (payload.type === 'Q' && question.id === payload.id) ||
            section.status === false ||
            (newSurveyFormat.showFirstSection === false && payload.type === 'S')
          )
            question.status = false;
          if (Object.prototype.hasOwnProperty.call(question, 'options') === true) {
            /*
            // antes: se cambia el estado de opciones eliminadas
            question.options = question.options.map((option) => {
              if ((payload.type === 'O' && option.id === payload.id) || question.status === false)
                option.status = false;
              return option;
            });*/
            // cambios: se elimina la opcion en frontend y bd automaticamente le cambia el estado
            let index = question.options.findIndex((el) => payload.type && el.id === payload.id);
            console.log(index, ', index');
            question.options.splice(index, 1);
            if (payload.type !== 'O' && question.status === false) {
              question.options = question.options.map((option) => {
                option.status = false;
                return option;
              });
            }
          }
          return question;
        });
        return section;
      });
      commit('SET_FORMATO_ENCUESTA', newSurveyFormat);

      if (payload.id === payload.selection.id || sectionsActives.length === 2) {
        let firstActive = null;
        let typeSelect = 'S';
        let questionsActive = [];
        switch (payload.type) {
          case 'S':
            firstActive = newSurveyFormat.sections.find((el) => el.status === true);
            break;
          case 'Q':
            firstActive = newSurveyFormat.sections.find((el) => el.id === payload.idParent);
            questionsActive = firstActive.questions.filter((el) => el.status === true);
            if (questionsActive.length > 0) {
              firstActive = firstActive.questions.find((el) => el.status === true);
              typeSelect = 'Q';
            }
            break;
        }

        if (firstActive) {
          payload.selection = {
            id: firstActive.id,
            type: typeSelect,
            idParent: firstActive.idParent,
            position: firstActive.position
          };
        }

        commit('SET_FORMATO_SELECCION', payload.selection);
      }
    },
    changeOrderElementoFormato({ commit }, payload) {
      let elementosActivos = [];
      let elementosInactivos = [];
      let pos = 0;
      let positionElement = payload.element.position;
      let posDirection = payload.direction === 'U' ? -1 : 1;

      switch (payload.type) {
        case 'S':
          elementosActivos = payload.surveyFormat.sections.filter((el) => el.status === true);
          elementosInactivos = payload.surveyFormat.sections.filter((el) => el.status === false);
          elementosActivos.forEach((el, i) => {
            if (payload.element.id === el.id) pos = i;
          });
          elementosActivos[pos].position = elementosActivos[pos + posDirection].position;
          elementosActivos[pos + posDirection].position = positionElement;
          payload.surveyFormat.sections = elementosInactivos.concat(elementosActivos);
          payload.surveyFormat.sections.sort((a, b) => a.position - b.position);
          break;
        case 'Q':
          payload.surveyFormat.sections = payload.surveyFormat.sections.map((section) => {
            if (section.id === payload.element.idParent) {
              elementosActivos = section.questions.filter((el) => el.status === true);
              elementosInactivos = section.questions.filter((el) => el.status === false);
              elementosActivos.forEach((el, i) => {
                if (payload.element.id === el.id) pos = i;
              });
              elementosActivos[pos].position = elementosActivos[pos + posDirection].position;
              elementosActivos[pos + posDirection].position = positionElement;
              section.questions = elementosInactivos.concat(elementosActivos);
              section.questions.sort((a, b) => a.position - b.position);
            }
            return section;
          });
          break;
      }
      payload.surveyFormat.sections = payload.surveyFormat.sections.map((section, posS) => {
        section.position = posS + 1;
        section.questions = section.questions.map((question, posQ) => {
          question.position = posQ + 1;
          return question;
        });
        return section;
      });

      commit('SET_FORMATO_ENCUESTA', payload.surveyFormat);
    },
    duplicateElementoFormato({ commit }, payload) {
      let newElement = { ...payload.element };
      let position = newElement.position;
      switch (payload.type) {
        case 'E':
          newElement.id = uuidv4();
          newElement.sections = newElement.sections.map((section) => {
            let sectionVal = { ...section };
            sectionVal.id = uuidv4();
            sectionVal.idParent = newElement.id;
            sectionVal.isNew = true;
            sectionVal.questions = sectionVal.questions.map((question) => {
              let questionVal = { ...question };
              questionVal.id = uuidv4();
              questionVal.idParent = section.id;
              questionVal.isNew = true;
              if (Object.prototype.hasOwnProperty.call(questionVal, 'options') === true) {
                questionVal.options = questionVal.options.map((option) => {
                  let optionVal = { ...option };
                  optionVal.id = uuidv4();
                  optionVal.idParent = question.id;
                  optionVal.isNew = true;
                  return optionVal;
                });
              }
              return questionVal;
            });
            return sectionVal;
          });
          break;
        case 'S':
          newElement.id = uuidv4();
          newElement.questions = newElement.questions.map((question) => {
            let questionVal = { ...question };
            questionVal.id = uuidv4();
            questionVal.idParent = newElement.id;
            if (Object.prototype.hasOwnProperty.call(questionVal, 'options') === true) {
              questionVal.options = questionVal.options.map((option) => {
                let optionVal = { ...option };
                optionVal.id = uuidv4();
                optionVal.idParent = question.id;
                return optionVal;
              });
            }
            return questionVal;
          });
          payload.surveyFormat.sections.splice(position, 0, newElement);
          payload.surveyFormat.sections.map((el, i) => {
            el.position = i + 1;
          });
          break;
        case 'Q':
          newElement.id = uuidv4();
          if (Object.prototype.hasOwnProperty.call(newElement, 'options') === true) {
            newElement.options = newElement.options.map((option) => {
              let optionVal = { ...option };
              optionVal.id = uuidv4();
              optionVal.idParent = newElement.id;
              return optionVal;
            });
          }
          payload.surveyFormat.sections[payload.posParent].questions.splice(position, 0, newElement);
          payload.surveyFormat.sections[payload.posParent].questions.map((el, i) => {
            el.position = i + 1;
          });
      }
      commit('SET_FORMATO_ENCUESTA', payload.surveyFormat);
    },
    duplicateFormato({ commit }, payload) {
      let surveyFormat = { ...payload };
      surveyFormat.id = uuidv4();
      surveyFormat.title = null;
      surveyFormat.campaign = null;
      surveyFormat.campaignId = null;
      surveyFormat.description = null;
      surveyFormat.startDate = null;
      surveyFormat.endDate = null;
      surveyFormat.sections = surveyFormat.sections.map((section) => {
        section.id = uuidv4();
        section.idParent = surveyFormat.id;
        section.isNew = true;
        section.questions.map((question) => {
          question.id = uuidv4();
          question.idParent = section.id;
          question.isNew = true;
          if (Object.prototype.hasOwnProperty.call(question, 'options') === true) {
            question.options.map((option) => {
              option.id = uuidv4();
              option.idParent = question.id;
              option.isNew = true;
              return option;
            });
          }
          return question;
        });
        return section;
      });
      commit('SET_FORMATO_ENCUESTA', surveyFormat);

      let selection = {
        id: surveyFormat.sections[0].id,
        type: 'S',
        idParent: surveyFormat.sections[0].idParent,
        position: surveyFormat.sections[0].position
      };

      commit('SET_FORMATO_SELECCION', selection);
    }
  },
  modules: {},
  getters: {}
});
