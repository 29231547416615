import { decryptData } from '@/components4x/utils/utils4x';
import HttpHandlerService from '@/services/httpHandlerService';
import LoadService from '../../gquest/services/LoadService';

async function loadPermissions(store) {
  let modules = [];

  if (localStorage.getItem('led17h9p2x') !== null) {
    const http = new HttpHandlerService(new LoadService(), null, { token: true, name: 'led17h9p2x' });
    const res = await http.get().execResource('load');
    if (res.status) {
      modules = decryptData(res.data, true);
    }
  }

  await store.dispatch('updatePermission', modules);
}

export { loadPermissions };
